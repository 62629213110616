.center {
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;
}

.title {
    font-size: 2.5rem;
    color: #333; /* Change the color to your preference */
    margin-bottom: 20px;
}

.description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555; /* Change the color to your preference */
    margin-bottom: 10px;
}
